<template>
    <div class="login">
        <div class="containes">
            <div class="rt">
                <div class="login_box">
                    <div class="title">欢迎登录</div>
                    <el-form
                        :model="loginForm"
                        :rules="rules"
                        ref="loginForm"
                        class="loginForm"
                    >
                        <el-form-item prop="username">
                            <el-input
                                v-model="loginForm.username"
                                placeholder="账号/邮箱/手机号"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input
                                type="password"
                                v-model="loginForm.password"
                                placeholder="请输入密码"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                class="loginBtn"
                                type="primary"
                                @click="submitForm('loginForm')"
                                >登 录</el-button
                            >
                        </el-form-item>
                    </el-form>
                    <div class="prompt" v-if="errorMessage">
                        {{ errorMessage }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import login from "@/api/login";

export default {
    data() {
        return {
            loginForm: {},
            errorMessage: '',
            rules: {
                username: [
                    { required: true, message: "请输入账号/邮箱/手机号", trigger: "blur" },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                ],
            },
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const { username, password } = this.loginForm;
                    const params = {
                        username: username.trim(),
                        password,
                    };
                    login
                        .login(params)
                        .then((data) => {
                            if (data.token) {
                                this.errorMessage = ''
                                localStorage.setItem("token", data.token);
                                localStorage.setItem(
                                    "user",
                                    JSON.stringify(data.user)
                                );
                                this.$router.push({ name: "dashbord" });
                            } else {
                                this.errorMessage = data.message
                            }
                        })
                        .catch((error) => {
                            if (error.data.code === 403) {
                                this.errorMessage = '账号已被禁用，请联系管理员'
                            } else {
                                this.errorMessage = '您的账号名或密码错误，请重新输入'
                            }
                        });
                } else {
                    this.errorMessage = ''
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
.login {
    .el-input {
        .el-input__inner {
            height: 44px;
            line-height: 44px;
            &::placeholder {
                color: rgba($color: #222222, $alpha: 0.25);
            }
        }
    }
    .error_red {
        .el-input__inner,
        .el-input.is-active .el-input__inner,
        .el-input__inner:focus {
            border-color: #ff6a6a;
        }
    }
}
</style>

<style lang="scss" scoped>
.login {
    height: 100%;
    width: 100%;
    .containes {
        height: calc(100% - 61px);
        display: flex;
        justify-content: center;
        padding-top: 10vh;
        box-sizing: border-box;
        .rt {
            height: 434px;
            background: #ffffff;
            box-shadow: 0px 2px 16px 0px rgba(34, 34, 34, 0.15);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            padding: 80px;
            box-sizing: border-box;
            .login_box {
                width: 320px;
                .title {
                    text-align: center;
                    height: 28px;
                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 28px;
                }
                .loginForm {
                    margin-top: 32px;
                    .loginBtn {
                        width: 100%;
                        height: 44px;
                    }
                }
                .prompt {
                    color: #f56c6c;
                    height: 22px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    line-height: 22px;
                    margin-top: 12px;
                }
                .login_btn {
                    width: 320px;
                    height: 44px;
                    line-height: 44px;
                    font-weight: 500;
                    font-family: "PingFangSC-Medium", "PingFang SC";
                }
            }
        }
    }
}
</style>
