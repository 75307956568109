import $axios from "./index";

const login = {
    async login(params) {
        const url = "/api/v1/login";
        return $axios.post(url, params).then((data) => {
            return data;
        });
    },
};

export default login;
